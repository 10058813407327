import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import { BEAUTY_CATEGORY_NODE_PREFIX } from '@centrito/common/constants'
import PATHS from '@centrito/common/paths'
import type { BrandPublic } from '@centrito/api/nest/platform/database/domain/entities/brand'
import { GetProductsFilterType as FilterType } from '@centrito/api/shared/enums'
import { trpc } from '@centrito/app/api/trpc'
import type { FilterOverlay } from '@centrito/app/components/Feed/FilterBar/Overlay/context/types'
import { useFiltersQuery } from '@centrito/app/utils/hooks/useFeedProducts/useFiltersQuery'
import { YStack } from '@centrito/ui/src'
import BaseGrid from '@centrito/ui/src/components/BaseGrid'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'
import { SubtitleText1 } from '@centrito/ui/src/components/text/new'
import { SearchBrand } from './components/SearchBrand'

export const BrandsByCategoriesSheet: React.FC<{
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  isOnlyContent?: boolean
  hasTitle?: boolean
  options?: FilterOverlay.OptionItem[]
  width?: number
  paddingHorizontal?: number
}> = ({ setIsOpen, options, width, paddingHorizontal, isOnlyContent = false, hasTitle = true }) => {
  const { filters } = useFiltersQuery()

  const categoryPrefix =
    (FilterType.CATEGORY_PREFIX in filters && filters[FilterType.CATEGORY_PREFIX]) || ''

  const filterByCategory =
    !isNil(categoryPrefix) && !isEmpty(categoryPrefix)
      ? categoryPrefix
      : BEAUTY_CATEGORY_NODE_PREFIX

  const { data } = trpc.catalog.brand.findManyByCategory.useQuery({
    categoryNodePrefix: filterByCategory,
  })

  const [searchBrandTerm, setSearchBrandTerm] = useState<string>('')
  const [brands, setBrands] = useState<BrandPublic[]>([])

  useEffect(() => {
    if (!isNil(data) && !isNil(data.brands)) {
      if (!isOnlyContent || (isOnlyContent && isNil(options))) {
        setBrands(data.brands)
        return
      }
      if (!isNil(options)) {
        const validBrands = options.map((option) => option.name) ?? []
        const filteredBrands =
          data.brands.filter((brand) => validBrands.includes(brand.name)) ?? data.brands
        setBrands(filteredBrands)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, options])

  return (
    <YStack width={width ?? '100%'} paddingHorizontal={paddingHorizontal ?? 38}>
      {!isOnlyContent && (
        <YStack gap={15} paddingRight={15}>
          {hasTitle && <SubtitleText1 paddingTop={15}>Todas las marcas</SubtitleText1>}
          <SearchBrand
            name={'Marcas'}
            textValue={searchBrandTerm}
            setTextValue={setSearchBrandTerm}
            placeholder={'Buscar por marca'}
          />
        </YStack>
      )}
      <BaseGrid>
        <BaseGrid.Row>
          <BaseGrid.Column
            stackProps={{
              flexDirection: 'row',
              paddingVertical: 8,
              flexWrap: 'wrap',
            }}
          >
            {brands
              .filter((brand) => {
                return brand.name.toLowerCase().includes(searchBrandTerm.toLowerCase())
              })
              .map((brand) => (
                <CircleFeedButton
                  onPress={
                    isNil(setIsOpen)
                      ? (): void => {}
                      : (): void => setIsOpen((prevIsOpen) => !prevIsOpen)
                  }
                  hasBackground
                  key={`brand-browser--${brand.name}`}
                  href={{
                    pathname: PATHS.Products.ListByBrand,
                    query:
                      !isNil(brand.id) && !isEmpty(brand.id)
                        ? {
                            brandId: brand.id,
                            categoryNodesPrefix: filterByCategory,
                          }
                        : {
                            categoryNodesPrefix: filterByCategory,
                          },
                  }}
                  imageSrc={brand.imageUrl ?? ''}
                  imageAlt={`${brand.name} logo`}
                  label={brand.name}
                />
              ))}
          </BaseGrid.Column>
        </BaseGrid.Row>
      </BaseGrid>
    </YStack>
  )
}
