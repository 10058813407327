import isEqual from 'lodash.isequal'
import isNil from 'lodash.isnil'
import { createParam } from 'solito'
import { type GetProducts } from '@centrito/api/shared/catalog'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import catalogClient from '@centrito/app/utils/services/catalog'
import getQueryParamFilters from '@centrito/app/utils/services/catalog/getProducts/utils/getQueryParamFilters'

const { useParams } = createParam()

const changeFilters = async (
  newFilters: GetProducts.Filters,
  onChangeCallback: (newQuery: any) => void,
  currentQuery: Record<string, string>,
): Promise<GetProducts.Filters> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { p, f, ...currentQueryNoPage } = currentQuery // Reset page to 0
  const newQueryParamFilters = getQueryParamFilters(newFilters)
  const newFiltersQueryParams =
    catalogClient.getProducts.filtersToFiltersQueryParams(newQueryParamFilters)

  const newQuery = {
    ...currentQueryNoPage,
    ...(!isNil(newFiltersQueryParams?.f) && newFiltersQueryParams),
  }

  const didQueryChange = !isEqual(currentQuery, newQuery)

  if (didQueryChange) {
    onChangeCallback(newQuery)
  }

  return newFilters
}

const removeFilter = async (
  targetFilter: GetProducts.FilterItem,
  currentFilters: GetProducts.Filters,
  changeFilters: (newFilters: GetProducts.Filters) => Promise<GetProducts.Filters>,
): Promise<GetProducts.Filters> => {
  const type = targetFilter.type
  const target = targetFilter.value

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [type as GetProductsFilterType]: _, ...rest } = currentFilters

  if (
    (type === GetProductsFilterType.CATEGORY ||
      type === GetProductsFilterType.SUPPLIER ||
      type === GetProductsFilterType.COLOR ||
      type === GetProductsFilterType.BRAND_ID ||
      type === GetProductsFilterType.SIZE) &&
    typeof target === 'string'
  ) {
    const filtered = currentFilters?.[type]?.filter((value) => value !== target)

    if (!isNil(filtered)) {
      const newFilters = {
        ...rest,
        ...(filtered.length > 0 && { [type]: filtered }),
      }

      return await changeFilters(newFilters)
    }
  }

  return await changeFilters(rest)
}

export const useFiltersQueryCommon = ({
  pathnameFilters,
  onQueryChange,
}: {
  pathnameFilters: GetProducts.Filters
  onQueryChange: (newQuery: any) => void
}): {
  filters: GetProducts.Filters
  changeFilters: (newProducts: GetProducts.Filters) => Promise<GetProducts.Filters>
  removeFilter: (targetFilter: GetProducts.FilterItem) => Promise<GetProducts.Filters>
} => {
  const { params } = useParams()

  const queryParamsFilters =
    catalogClient.getProducts.filtersQueryParamsToFilters({ f: params?.f }) || {}

  const filters = {
    ...pathnameFilters,
    ...queryParamsFilters,
  }

  const _changeFilters = (newFilters: GetProducts.Filters): Promise<GetProducts.Filters> =>
    changeFilters(newFilters, onQueryChange, params)

  const _removeFilter = (targetFilter: GetProducts.FilterItem): Promise<GetProducts.Filters> =>
    removeFilter(targetFilter, filters, _changeFilters)

  return {
    filters: filters,
    changeFilters: _changeFilters,
    removeFilter: _removeFilter,
  }
}
