import React from 'react'
import type { StackProps } from 'tamagui'
import { XStack } from 'tamagui'

export interface BaseGridRowProps {
  stackProps?: StackProps
  children: React.ReactNode
}

const BaseGridRow: React.FC<BaseGridRowProps> = ({ children, stackProps }) => {
  return (
    <XStack flexGrow={1} {...stackProps}>
      {children}
    </XStack>
  )
}

export default BaseGridRow
