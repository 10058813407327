export enum GetProductsFilterType {
  BRAND_ID,
  CATEGORY,
  COLOR,
  SIZE,
  CATEGORY_PREFIX,
  LIST_ID,
  PRICE_RANGE,
  SEARCH_TERM,
  SUPPLIER,
}

export enum PrepaidCartReason {
  HAS_HALLOWEEN_ITEMS = 'HAS_HALLOWEEN_ITEMS',
  // HAS_JUGUETES = 'HAS_JUGUETES',
  THRESHOLD_EXCEEDED = 'THRESHOLD_EXCEEDED',
  BANNED_FROM_CASH = 'BANNED_FROM_CASH',
  ACTIVE_THRESHOLD_EXCEEDED = 'ACTIVE_THRESHOLD_EXCEEDED',
  NATIONAL_DELIVERY = 'NATIONAL_DELIVERY',
}

export enum OrderItemAlternativeOption {
  DEFAULT = 'DEFAULT',
  PICK_MORE_ALTERNATIVE = 'PICK_MORE_ALTERNATIVE',
  CANCEL_NO_ALTERNATIVE = 'CANCEL_NO_ALTERNATIVE',
  PENDING_CONTACT_CX = 'PENDING_CONTACT_CX',
}
