import type { NextRouter } from 'next/router'
import isNil from 'lodash.isnil'
import isUndefined from 'lodash.isundefined'
import type { UrlObject } from 'url'
import { queryParamsToQueryString } from './helpers'

// Router.replace but doesn't url encode the query strings
export const replaceNoEncoding = async (
  router: NextRouter,
  urlObject: UrlObject,
): Promise<boolean> => {
  return await router.replace(urlObject, undefined, { shallow: true, scroll: false })
  // const { query } = urlObject
  // if (!isUndefined(query) && !isNil(query) && typeof query !== 'string') {
  //   const sluglessQuery = Object.fromEntries(
  //     Object.entries(query).filter(([k]) => !router.pathname.includes(`[${k}]`)),
  //   )
  //   const sluglessPathname = Object.entries(query).reduce(
  //     (p, [slugKey, slugValue]) => p.replace(`[${slugKey}]`, `${slugValue}`),
  //     router.pathname,
  //   )
  //   const qs = queryParamsToQueryString(sluglessQuery)
  //   return await router.replace(`${sluglessPathname}${qs}`, undefined, { shallow: true })
  // } else {
  //   return await router.replace(urlObject, undefined, { shallow: true })
  // }
}
