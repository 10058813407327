export type CategoryGroup = {
  display: string
  url: string
  image?: string
  backgroundColor?: string
}

export const GROUP_CATEGORIES_GENDER: CategoryGroup[] = [
  {
    display: 'MUJER',
    url: 'MUJER',
  },
  {
    display: 'HOMBRE',
    url: 'HOMBRE',
  },
  {
    display: 'NIÑA',
    url: 'NIÑA',
  },
  {
    display: 'NIÑO',
    url: 'NIÑO',
  },
]
