import * as methods from './public'

export class GetProductsPublicClient {
  public queryToQueryParams = methods.queryToQueryParams
  public filtersToFiltersQueryParams = methods.filtersToFiltersQueryParams
  public filtersQueryParamsToFilters = methods.filtersQueryParamsToFilters

  protected validateFilter = methods.private.validateFilters
}

const getProductsPublicClient = new GetProductsPublicClient()

export default getProductsPublicClient
