import { useRouter } from 'next/router'
import { type GetProducts } from '@centrito/api/shared/catalog'
import { replaceNoEncoding } from '@centrito/app/utils/router'
import getPathnameFilters from '@centrito/app/utils/services/catalog/getProducts/utils/getPathnameFilters'
import { useFiltersQueryCommon } from './common'

export const useFiltersQuery = (): {
  filters: GetProducts.Filters
  changeFilters: (newProducts: GetProducts.Filters) => Promise<GetProducts.Filters>
  removeFilter: (targetFilter: GetProducts.FilterItem) => Promise<GetProducts.Filters>
} => {
  const router = useRouter()
  const pathnameFilters = getPathnameFilters(router)
  // Use an immediately-invoked async function to await the promise
  const onQueryChange = (newQuery: any): void => {
    ;(async (): Promise<void> => {
      try {
        await replaceNoEncoding(router, { query: newQuery })
      } catch (error) {
        console.error('Failed to change query:', error)
      }
    })()
  }

  return useFiltersQueryCommon({
    pathnameFilters,
    onQueryChange,
  })
}
