import type { GetProducts } from '@centrito/api/shared/catalog'
import { GetProductsFilterType } from '@centrito/api/shared/enums'

const getQueryParamFilters = (allFilters: GetProducts.Filters): GetProducts.Filters => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [GetProductsFilterType.CATEGORY_PREFIX]: _,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [GetProductsFilterType.SEARCH_TERM]: __,
    ...newRouteFilters
  } = allFilters
  return newRouteFilters
}

export default getQueryParamFilters
