import * as React from 'react'

const CircleArrowUpSvg = (props: any) => (
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 4.5C10.2513 4.5 4.375 10.5442 4.375 18C4.375 25.4558 10.2513 31.5 17.5 31.5C24.7487 31.5 30.625 25.4558 30.625 18C30.625 10.5442 24.7487 4.5 17.5 4.5Z"
      stroke="#55808A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.125 19.5L17.5 15L21.875 19.5"
      stroke="#55808A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default CircleArrowUpSvg
