import React from 'react'
import { Building2 } from '@tamagui/lucide-icons'
import { Circle, YStack, styled, useWindowDimensions } from '@centrito/ui/src'
import { DetailText2 } from '@centrito/ui/src/components/text/new/detail/DetailText2'
import CircleImageWrapper from './CircleImageWrapper/'

type HrefCircleFeedButton =
  | string
  | {
      pathname?: string | null
      query?: {
        categoryNodesPrefix?: string
        listId?: string
        selectedCategory?: string
        brandId?: string
      }
    }
export interface CircleFeedButtonProps {
  href?: HrefCircleFeedButton
  label: string
  imageSrc: string
  imageAlt?: string
  highlighted?: boolean
  hasBackground?: boolean
  onPress?: () => void
  containerHeight?: number
}

const Container = styled(YStack, {
  width: 72,
  alignItems: 'center',
  justifyContent: 'flex-start',
  rowGap: 5,
})

export const CircleFeedButton: React.FC<CircleFeedButtonProps> = ({
  href = undefined,
  label,
  imageSrc,
  imageAlt = label,
  highlighted = false,
  hasBackground = false,
  onPress = undefined,
  containerHeight = 80,
}) => {
  const { width } = useWindowDimensions()
  const fontSize = width < 400 ? 9 : 10
  return (
    <YStack flexGrow={1} flexShrink={1} maxWidth={80}>
      <Container onPress={onPress} height={containerHeight} cursor="pointer">
        <Circle
          borderWidth={highlighted ? 2 : 0}
          borderColor={highlighted ? '$Gray2' : '$pureWhite'}
          borderRadius={10}
        >
          <CircleImageWrapper
            hasBackground={hasBackground}
            href={href}
            imageSrc={imageSrc}
            label={label}
            imageAlt={imageAlt}
            placeholderIcon={<Building2 size={20} />}
          />
        </Circle>
        <DetailText2 lineHeight={13} textAlign="center" color="$Gray2" fontSize={fontSize}>
          {label}
        </DetailText2>
      </Container>
    </YStack>
  )
}
