import type { NextRouter } from 'next/router'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import PATHS from '@centrito/common/paths'
import { GetProductsFilterType } from '@centrito/api/shared/enums'

const isStringQueryParam = (param: unknown): boolean => isString(param) && !isNil(param)

const buildFilter = (type: GetProductsFilterType, value: string): Record<string, string> => ({
  [type]: value,
})

const getPathnameFilters = (router: NextRouter): Record<string, any> => {
  const { pathname, query } = router

  switch (pathname) {
    case PATHS.Products.ListByCategoryPrefix:
      if (isStringQueryParam(query?.categoryNodesPrefix)) {
        return buildFilter(
          GetProductsFilterType.CATEGORY_PREFIX,
          query.categoryNodesPrefix as string,
        )
      }
      break
    case PATHS.Products.ListByBrand:
      if (isStringQueryParam(query?.brandId) && isStringQueryParam(query?.categoryNodesPrefix)) {
        return {
          [GetProductsFilterType.BRAND_ID]: query.brandId as string,
          [GetProductsFilterType.CATEGORY_PREFIX]: query.categoryNodesPrefix as string,
        }
      }
      if (isStringQueryParam(query?.brandId)) {
        return buildFilter(GetProductsFilterType.BRAND_ID, query.brandId as string)
      }
      break
    case PATHS.Products.List:
      if (isStringQueryParam(query?.listId)) {
        const filters: Record<number, string> = {
          [GetProductsFilterType.LIST_ID]: query.listId as string,
        }
        if (isStringQueryParam(query?.selectedCategory)) {
          filters[GetProductsFilterType.CATEGORY_PREFIX] = query.selectedCategory as string
        }
        return filters
      }
      break

    case PATHS.Search.Text:
      if (isStringQueryParam(query?.searchTerm)) {
        return buildFilter(GetProductsFilterType.SEARCH_TERM, query.searchTerm as string)
      }
      break

    case PATHS.Products.ListByMerchant:
      if (isStringQueryParam(query?.merchantId)) {
        return buildFilter(GetProductsFilterType.SUPPLIER, query.merchantId as string)
      }
      break

    default:
      return {}
  }

  return {}
}

export default getPathnameFilters
