import { isArray, isUndefined } from 'lodash'
import type { GetProducts } from '@centrito/api/shared/catalog'
import { GetProductsFilterType as FilterType } from '@centrito/api/shared/enums'
import { GROUP_CATEGORIES_GENDER } from '@centrito/app/utils/components/HomePage/CategoryGroups'
import type { GetProductsPublicClient } from '@centrito/app/utils/services/catalog/getProducts'

const isValidFilter = {
  [FilterType.BRAND_ID]: (filterValue: any): boolean =>
    isArray(filterValue) && filterValue.length > 0 && typeof filterValue[0] === 'string',
  [FilterType.CATEGORY]: (filterValue: any): boolean =>
    isArray(filterValue) && filterValue.length > 0 && typeof filterValue[0] === 'string',
  [FilterType.COLOR]: (filterValue: any): boolean =>
    isArray(filterValue) && filterValue.length > 0 && typeof filterValue[0] === 'string',
  [FilterType.SIZE]: (filterValue: any): boolean =>
    isArray(filterValue) && filterValue.length > 0 && typeof filterValue[0] === 'string',
  [FilterType.CATEGORY_PREFIX]: (filterValue: any): boolean =>
    typeof filterValue === 'string' &&
    GROUP_CATEGORIES_GENDER.some((categoryGroup) => categoryGroup.url === filterValue),
  [FilterType.LIST_ID]: (filterValue: any): boolean =>
    typeof filterValue === 'string' && filterValue.length > 0,
  [FilterType.PRICE_RANGE]: (filterValue: any): boolean =>
    filterValue.high < 1000000 && filterValue.low > 0,
  [FilterType.SEARCH_TERM]: (filterValue: any): boolean =>
    typeof filterValue === 'string' && filterValue.length > 0,
  [FilterType.SUPPLIER]: (filterValue: any): boolean =>
    isArray(filterValue) && filterValue.length > 0 && typeof filterValue[0] === 'string',
}

export default function (this: GetProductsPublicClient, filters: GetProducts.Filters): void {
  if (typeof filters === 'object') {
    Object.keys(filters).map((filterKey) => {
      const isValidFilterKey = Object.values(FilterType).includes(parseInt(filterKey))
      if (isValidFilterKey) {
        const filterType = parseInt(filterKey) as FilterType
        const filterValue = filters?.[filterType]
        if (!isUndefined(filterValue) && !isValidFilter[filterType](filterValue)) {
          throw new Error(`Invalid filter for ${filterType}: ${JSON.stringify(filterValue)}`)
        }
      } else {
        throw new Error(`Invalid filter key: ${filterKey}`)
      }
    })
  } else {
    throw new Error('Invalid filters input is not an object')
  }
}
