import React from 'react'
import type { StackProps } from 'tamagui'
import { Stack } from 'tamagui'

export interface BaseGridColumnProps {
  stackProps?: StackProps
  children: React.ReactNode
}

export const BaseGridColumn: React.FC<BaseGridColumnProps> = ({ children, stackProps }) => {
  return (
    <Stack flexGrow={1} flexBasis={0} {...stackProps}>
      {children}
    </Stack>
  )
}

export default BaseGridColumn
