import JsonURL from '@jsonurl/jsonurl'
import isUndefined from 'lodash.isundefined'
import type { GetProducts } from '@centrito/api/shared/catalog'
import type { GetProductsPublicClient } from '@centrito/app/utils/services/catalog/getProducts'

export default function (
  this: GetProductsPublicClient,
  filtersQueryParam: GetProducts.FiltersQueryParam,
): GetProducts.Filters {
  const { f } = filtersQueryParam
  if (isUndefined(f)) return {}
  const filter = f as string
  // check if filter is encoded by matching encoded string illegal characters
  const encodedFilter = filter.match(/[-a-zA-Z0-9\u1F60-\uFFFF@:%_\+.~#?&//=!'(),;*\$\[\]]*/g)
    ? encodeURI(filter)
    : filter
  const filters = JsonURL.parse(decodeURIComponent(encodedFilter))
  // TODO: Validate filters
  return filters
}
